import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { MainLayout, Menu, useLanguage, withLanguageProvider } from '@tmp'
import { GetFrontPageAndBookMeetingSectionQuery } from '@gql-types'
import { useNewsData } from '@src/hooks/useNewsData'
import { Feature, HeroBanner, RichText, Text, NewsArticleTable, Footage, Video, BookMeetingSection } from '@cmp'
import { EventHighlights } from '@src/query-components'
import { allLocales } from '@src/utils/locale'
import { objectToPath } from '@src/utils/objectToPath'
import { assertDefined, footageExists } from '@src/utils/helpers'
import { makePath } from '@src/utils/routes'

const FrontPageTemplate = ({ data: { contentfulFrontPage, allContentfulBookMeetingSection } }: PageProps<GetFrontPageAndBookMeetingSectionQuery>) => {
  contentfulFrontPage = assertDefined(contentfulFrontPage)
  allContentfulBookMeetingSection = assertDefined(allContentfulBookMeetingSection)
  const { t, locale, dateFormat } = useLanguage()
  const articles = useNewsData(locale, dateFormat)

  const bookMeetingSection = allContentfulBookMeetingSection.edges[0].node

  return (
    <MainLayout
      localizedUrls={allLocales.map(locale => ({ locale, route: { type: 'frontpage' } }))}
      seo={contentfulFrontPage?.seo}
      defaultSeoOverwrites={{ title: contentfulFrontPage.title, ogTitle: contentfulFrontPage.title }}
      hideMenu
    >
      <div
        className={`relative flex flex-col min-h-screen pb-10 bg-left-top bg-no-repeat bg-h-full bg-quasi1-m lg:bg-quasi1`}
      >
        <Video className='absolute h-full' videoSrcURL='/harpa-stytt.mp4' autoplay loop muted hideWhileLoad />

        <Menu localizedUrls={allLocales.map(locale => ({ locale, route: { type: 'frontpage' } }))} />
        {contentfulFrontPage?.heroBanner?.image && (
          <HeroBanner
            className='flex-grow mt-7 gutter'
            title={contentfulFrontPage?.heroBanner?.title || ''}
            description={contentfulFrontPage.heroBanner.description?.description || ''}
            notificationWrapper={{
              notifications: (contentfulFrontPage?.heroBanner?.notificationWrapper?.notifications ?? []).map(
                notification => {
                  return {
                    title: notification?.title ?? '',
                    description: notification?.description ?? '',
                    image: notification?.image?.gatsbyImageData,
                    link: objectToPath(notification?.link),
                  }
                },
              ),
            }}
            button={{
              label: contentfulFrontPage?.heroBanner?.link?.label || '',
              link: objectToPath(contentfulFrontPage?.heroBanner?.link),
            }}
            imageLeft={{
              img: contentfulFrontPage.heroBanner.image.gatsbyImageData,
              link: objectToPath(contentfulFrontPage?.heroBanner?.image1Link),
              title: contentfulFrontPage?.heroBanner?.image1Link?.title || '',
              subTitle: contentfulFrontPage?.heroBanner?.image1Link?.label || '',
            }}
            imageRight={{
              img: contentfulFrontPage.heroBanner?.image2?.gatsbyImageData,
              link: objectToPath(contentfulFrontPage?.heroBanner?.image2Link),
              title: contentfulFrontPage?.heroBanner?.image2Link?.title || '',
              subTitle: contentfulFrontPage?.heroBanner?.image2Link?.label || '',
            }}
          />
        )}
      </div>
      <EventHighlights sliderContainerClass='pb-40 pt-16 md:pt-28' />
      <BookMeetingSection
        title={bookMeetingSection?.bookTitle ?? ''}
        introText={<RichText document={bookMeetingSection?.bookText} />}
        image={bookMeetingSection?.bookImage?.gatsbyImageData ?? ''}
        link={makePath(locale, { type: 'fundarherbergi' })}
        locale={locale}
        t={t}
      />
      {contentfulFrontPage?.aboutTitle && (
        <>
          {footageExists(contentfulFrontPage?.aboutFootage) && (
            <div className='relative'>
              <div className='sticky top-0 z-10 text-dark-green-400'>
                <div className='flex flex-col border-t border-current lg:flex-row'>
                  <div className='relative lg:w-2/12'>
                    <Text variant='intro' weight='heavy' className='py-6 leading-none gutter'>
                      {contentfulFrontPage?.title ?? ''} <span className='lg:hidden'>/</span>
                    </Text>
                    <div className='absolute top-0 right-0 hidden w-px bg-current h-1/2 lg:block' />
                  </div>
                  <Text variant='intro' weight='heavy' className='leading-none gutter lg:px-5 lg:py-6 lg:block'>
                    {contentfulFrontPage?.aboutTitle ?? ''}
                  </Text>
                </div>
              </div>
              <Text
                className='gutter lg:col-count-2 mt-14 lg:w-10/12 lg:pl-5 lg:ml-auto mb-14'
                weight='light'
                as='div'
              >
                {contentfulFrontPage?.aboutText && <RichText document={contentfulFrontPage.aboutText} />}
              </Text>
              <div className='gutter lg:w-10/12 lg:pl-5 lg:ml-auto pb-50'>
                <Footage
                  videoSrcURL={
                    contentfulFrontPage.aboutFootage?.link ||
                    contentfulFrontPage.aboutFootage?.videoFile?.file?.url
                  }
                  image={contentfulFrontPage.aboutFootage?.fallbackImage?.gatsbyImageData}
                  autoplay
                  controls
                  volume={0}
                  muted
                  loop
                />
              </div>
            </div>
          )}
        </>
      )}
      <div className='relative pt-20 bg-yellow-400 pb-50 front-container-clip'>
        <div className='sticky top-0 z-10 flex flex-col mt-20 border-t border-current lg:flex-row'>
          <div className='relative lg:w-6/12'>
            <Text variant='intro' weight='heavy' className='py-6 leading-none gutter'>
              {t('about-harpa')}
            </Text>
            <div className='absolute top-0 right-0 hidden w-px bg-current h-1/2 lg:block' />
          </div>
          <Text variant='intro' weight='heavy' className='hidden leading-none gutter lg:px-5 lg:py-6 lg:block'>
            {t('scenes')}
          </Text>
        </div>
        {contentfulFrontPage.featureSections?.map((f, idx) => (
          <div key={idx} className='mt-16 lg:mt-50 gutter'>
            <Feature
              title={f?.title || ''}
              button={{
                label: f?.button?.label || '',
                link: f?.button?.internalLink?.slug || f?.button?.externalLink || '',
              }}
              description={f?.description?.description || ''}
              featureImage={f?.featureImage?.gatsbyImageData}
              variant='secondary'
            />
          </div>
        ))}
      </div>
      <div className='relative pt-10 md:pt-50'>
        <div className='sticky top-0 z-10 flex flex-col mb-10 text-blue-400 border-t border-blue-400 lg:flex-row'>
          <div className='relative lg:w-2/12'>
            <Text variant='intro' weight='heavy' className='py-6 leading-none gutter'>
              {t('news')} <span className='lg:hidden'>/</span>
            </Text>

            <div className='absolute top-0 right-0 hidden w-px bg-blue-400 h-1/2 lg:block' />
          </div>
          <Text variant='intro' weight='heavy' className='leading-none gutter lg:px-4 lg:py-6 lg:block'>
            {t('all-the-latest')}
          </Text>
        </div>
        <NewsArticleTable
          title={t('news')}
          numArticles={3}
          translate={t}
          articles={articles}
          hideHeaders
          seeAllButton
        />
      </div>
    </MainLayout>
  )
}

export default withLanguageProvider(FrontPageTemplate)

export const frontPageQuery = graphql`
  fragment eventCardFragment on TixHarpaDateConnection {
    nodes {
      name
      startDate
      slug
      featuredImage {
        childImageSharp {
          resize(width: 760, quality: 85) {
            src
          }
        }
      }
    }
  }
  query GetFrontPageAndBookMeetingSection($locale: String) {
    contentfulFrontPage(tag: { eq: "FRONT_PAGE" }, node_locale: { eq: $locale }) {
      ...frontPageFragment
    }
    allContentfulBookMeetingSection {
      edges {
        node {
          id
          bookImage {
            gatsbyImageData
          }
          bookText {
            raw
          }
          bookTitle
        }
      }
    }
  }
`
