import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Text, Link } from '@cmp'
import { getTextStyles } from '../Text'
import { motion } from 'framer-motion'

export interface NotificationWrapperProps {
  /**
   * Useful for positioning the notification wrapper correctly and more.
   */
  className?: string
  /**
   * List of notifications in the carousel. Only one displayed at a time.
   */
  notifications: NotificationType[]
  /**
   * Time in ms between slide transitions.
   */
  loopTimer?: number
}

type NotificationType = {
  /**
   * Title displayed on the top left of the notification box.
   */
  title: string
  /**
   * Describes the notification in very few words. Directly below the title.
   */
  description: string
  /**
   * Image URL on the far right of the notification box.
   */
  image: IGatsbyImageData
  /**
   * An URL that takes you to the page described in the notification box.
   */
  link: string
  className?: string
}

const Notification = ({ title, description, link, image, className }: NotificationType) => (
  <Link noStyle to={link} className={cn('h-[90px] flex text-white text-left overflow-hidden', className)}>
    <div className='px-4 pt-4 pb-2'>
      <Text variant='h4' className='mb-4'>
        {title}
      </Text>
      <Text variant='sm' className='line-clamp-2'>
        {description}
      </Text>
    </div>
    <div className='ml-auto'>
      <GatsbyImage className='h-[90px] w-[90px]' alt='' image={image} />
    </div>
  </Link>
)

const backgrounds = ['bg-dark-400', 'bg-dark-300', 'bg-dark-200', 'bg-dark-100']

export const NotificationWrapper = ({
  notifications,
  className = '',
  loopTimer = 9000,
}: NotificationWrapperProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [notificationList, setNotificationList] = useState(notifications.map((n, idx) => ({ key: idx, ...n })))

  useEffect(() => {
    if (loopTimer !== null && !isOpen && notificationList.length > 0) {
      let notificationsTemp = notificationList
      let id = setInterval(() => {
        const [first, ...rest] = notificationsTemp
        notificationsTemp = [...rest, first]
        setNotificationList(notificationsTemp)
      }, loopTimer)
      return () => clearInterval(id)
    }
  }, [loopTimer, setNotificationList, isOpen])

  return (
    <div
      className={cn('flex flex-col w-full transition-transform h-[120px]', className, {
        'z-50 -translate-y-5': isOpen,
      })}
    > 
      <div className='relative min-h-[140px]'>
        {notificationList.map(({ title, description, image, link, key }, idx) => {
          return (
            <motion.div
              key={key}
              animate={isOpen ? 'open' : 'closed'}
              className='absolute bottom-0 left-0 right-0'
              style={{ zIndex: notificationList.length - idx }}
              variants={{
                open: {
                  opacity: 1,
                  y: -100 * idx + '%',
                },
                closed: {
                  opacity: 1,
                  y: -20 * idx + 'px',
                  scale: 1 - 0.05 * idx,
                },
              }}
            >
              <Notification
                title={title}
                description={description}
                image={image}
                link={link}
                className={cn('mb-2 transition', isOpen ? 'bg-dark-400' : backgrounds[idx])}
              />
            </motion.div>
          )
        })}
        {!isOpen && (
          <button
            className='absolute top-0 left-0 z-20 w-full h-full focus:outline-none'
            onClick={() => {
              setIsOpen(true)
            }}
          ></button>
        )}
      </div>
      <button
        className={cn(
          'mt-2 transition transform focus:outline-none min-h-[20px]',
          getTextStyles({ variant: 'sm' }),
          {
            '-translate-y-3 opacity-0 pointer-events-none': !isOpen,
          },
        )}
        onClick={() => {
          setIsOpen(false)
        }}
      >
        Loka tilkynningum
      </button>
    </div>
  )
}

export default NotificationWrapper
