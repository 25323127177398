import React, { useState, useEffect } from 'react'
import { NotificationWrapperProps, Button, Text, Link } from '@cmp'
import { HyphenText } from '@connected'
import cn from 'classnames'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Tilt from 'react-parallax-tilt'
import NotificationWrapper from '../NotificationWrapper'
import { getTextStyles } from '../Text'

type ImageCard = {
  img: IGatsbyImageData
  link: string
  title: string
  subTitle: string
}

const LONGHEADLINELIMIT = 30
export interface HeroBannerProps {
  title: string
  longHeadline?: boolean
  description?: string
  className?: string
  notificationWrapper: NotificationWrapperProps
  button: {
    label: string
    link?: string
  }
  imageLeft: ImageCard
  imageRight: ImageCard
}

export const HeroBanner = ({
  title,
  description,
  notificationWrapper,
  button,
  className = '',
  imageLeft,
  imageRight,
}: HeroBannerProps) => {
  const [imageContainerZIndex, setImageContainerZIndex] = useState('z-1')
  const [currentHover, setCurrentHover] = useState('')
  const longHeadline = title.length > LONGHEADLINELIMIT

  useEffect(() => {
    // weird bug on safari where z-index is incorrectly rendered - forcing rerender on mount
    setTimeout(() => {
      setImageContainerZIndex('z-10')
    }, 50)
  }, [])
  const headlineVariant = longHeadline ? 'h1Alt2' : 'h1Alt'
  return (
    <div className={cn('flex flex-col md:flex-row', className)}>
      <div className='justify-center hidden w-full pr-12 lg:flex md:w-5/12 lg:w-7/12'>
        <ImageContainer
          img1={imageLeft.img}
          img2={imageRight.img}
          img1Link={imageLeft.link !== '/?' ? imageLeft.link : ''}
          img2Link={imageRight.link !== '/?' ? imageRight.link : ''}
          className='max-w-[90vh]'
          style={{
            // 7rem should be equal to the negative margin on the image container (-ml-28)
            width: 'calc(100% + 7rem)',
          }}
          currentHover={currentHover}
          setCurrentHover={setCurrentHover}
          img1Title={imageLeft.title}
          img1SubTitle={imageLeft.subTitle}
          img2Title={imageRight.title}
          img2SubTitle={imageRight.subTitle}
        />
      </div>
      <div
        className={cn(
          'flex flex-col justify-between flex-grow md:w-7/12 lg:w-5/12 transform transition duration-500',
          {
            'translate-x-2.5 opacity-90': currentHover === 'right',
            'translate-x-4 opacity-90': currentHover === 'left',
          },
        )}
      >
        <div className='mb-8 lg:mt-[10%] z-10'>
          <HyphenText variant={headlineVariant} as='h1' weight='heavy'>
            {title}
          </HyphenText>
        </div>
        <div className='flex flex-col justify-center flex-grow lg:ml-auto xl:w-4/5'>
          <Text className='mb-8 z-1' variant='intro'>
            {description}
          </Text>
          <div className={cn('w-full lg:hidden img1', imageContainerZIndex)}>
            <ImageContainer
              img1={imageLeft.img}
              img2={imageRight.img}
              img1Link={imageLeft.link}
              img2Link={imageRight.link}
              currentHover={currentHover}
              setCurrentHover={setCurrentHover}
              img1Title={imageLeft.title}
              img1SubTitle={imageLeft.subTitle}
              img2Title={imageRight.title}
              img2SubTitle={imageRight.subTitle}
            />
          </div>
          <Button className='z-20 mb-20 lg:mb-0 -mt-28 lg:mt-10' href={button.link} icon>
            {button.label}
          </Button>
        </div>
        <div className='relative flex-grow h-50 xl:ml-auto xl:w-4/5 '>
          <NotificationWrapper
            className='transform translate-x-1/2 bottom-5 right-1/2 absolute xl:w-4/5 min-w-[348px]'
            {...notificationWrapper}
          />
        </div>
      </div>
    </div>
  )
}

const ImageContainer = ({
  img1,
  img2,
  className,
  style,
  img1Link,
  img2Link,
  currentHover,
  img1Title,
  img1SubTitle,
  img2Title,
  img2SubTitle,
  setCurrentHover,
}: {
  img1: IGatsbyImageData
  img2: IGatsbyImageData
  className?: string
  style?: React.CSSProperties
  img1Link?: string
  img2Link?: string
  currentHover: string
  img1Title: string
  img1SubTitle: string
  img2Title: string
  img2SubTitle: string
  setCurrentHover: React.Dispatch<React.SetStateAction<string>>
}) => (
  <div className={cn('flex justify-between -ml-28', className)} style={style}>
    <div
      className={cn('w-[43.8323%] mt-[9.4611%] img1 transform block duration-500', {
        'translate-x-9 -translate-y-2': currentHover === 'left',
        '-translate-x-1 opacity-90': currentHover === 'right',
      })}
    >
      <Link
        to={img1Link}
        self
        noStyle
        onMouseEnter={() => {
          setCurrentHover('left')
        }}
        onMouseLeave={() => {
          setCurrentHover('')
        }}
      >
        <div className='relative'>
          <Tilt
            tiltMaxAngleX={3}
            tiltMaxAngleY={3}
            glareEnable={true}
            glareMaxOpacity={0.3}
            glareColor='white'
            glarePosition='all'
          >
            <div>
              <GatsbyImage
                alt=''
                image={img1}
                style={{
                  clipPath:
                    'polygon(-1.1522% 0, 19.6253% 0, 100% 0, 100% 96.1642%, 63.2688% 100%, -12.963% 96.1642%, -1.1522% 0)',
                }}
                onMouseEnter={() => {
                  setCurrentHover('left')
                }}
                onMouseLeave={() => {
                  setCurrentHover('')
                }}
              />
              {img1Title ? <div
                className={cn(
                  'bg-dark-400 absolute -bottom-4 left-[7%] right-[-3%] transform z-10 text-white px-6 py-3 text-p-alt transition duration-500 hidden lg:block',
                  {
                    'opacity-0': currentHover !== 'left',
                  },
                  getTextStyles({ weight: 'normal' }),
                )}
              >
                {img1Title}
                <p className={getTextStyles({ weight: 'light' })}>{img1SubTitle}</p>
              </div> : null}
            </div>
          </Tilt>
        </div>
      </Link>
    </div>
    <div
      className={cn('w-[54.7305%] img2 transform block duration-500', {
        'translate-x-9 translate-y-4 opacity-90': currentHover === 'left',
        'translate-x-1 translate-y-1': currentHover === 'right',
      })}
    >
      <Link
        to={img2Link}
        self
        noStyle
        onMouseEnter={() => {
          setCurrentHover('right')
        }}
        onMouseLeave={() => {
          setCurrentHover('')
        }}
      >
        <div className='relative'>
          <Tilt
            tiltMaxAngleX={3}
            tiltMaxAngleY={3}
            glareEnable={true}
            glareMaxOpacity={0.3}
            glareColor='white'
            glarePosition='all'
          >
            <div>
              <GatsbyImage
                alt=''
                image={img2}
                style={{
                  clipPath:
                    'polygon(0 11.2656%, 30.7359% 0, 100% 7.2439%, 91.7648% 100%, 34.7059% 99.4184%, 1.1764% 99.4184%, 0 11.2656%)',
                }}
                onMouseEnter={() => {
                  setCurrentHover('right')
                }}
                onMouseLeave={() => {
                  setCurrentHover('')
                }}
              />
              {img2Title ? <div
                className={cn(
                  'bg-dark-400 absolute -bottom-4 left-[7.6%] right-[16%] transform z-10 text-white px-6 py-3 text-p-alt transition duration-500 hidden lg:block',
                  {
                    'opacity-0 -translate-x-4 translate-y-4': currentHover !== 'right',
                  },
                  getTextStyles({ weight: 'normal' }),
                )}
              >
                {img2Title}
                <p className={getTextStyles({ weight: 'light' })}>{img2SubTitle}</p>
              </div> : null}
            </div>
          </Tilt>
        </div>
      </Link>
    </div>
  </div>
)

export default HeroBanner
