import React, { useState } from 'react'
import { HyphenText } from '@connected'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Dropdown, Text, Button } from '@cmp'
import { DatePickerSingle } from '@cmp/Datepicker/DatePickerSingle'
import { encodeQueryParams, NumberParam, StringParam } from 'use-query-params'
import cn from 'classnames'
import moment from 'moment'
import { navigate } from 'gatsby-link'
import { stringify } from 'qs'
import { getBlockedDays } from '@src/utils/helpers'
import { useStore } from '../BookMeetingSteps/Step-store'
export interface BookMeetingSectionProps {
  title?: string
  introText?: JSX.Element
  image?: IGatsbyImageData
  link?: string
  t: (s: string, defaultIS?: string | undefined, defaultEN?: string | undefined) => string
  locale?: string
}

export const BookMeetingSection = ({ title, introText, image, link, t, locale }: BookMeetingSectionProps) => {
  const [selectedGuestsTitle, setSelectedGuestsTitle] = useState<string>('gest-number')
  const [selectedDateTitle, setSelectedDateTitle] = useState<string>('meeting-date')

  const {
    selectedStartDate,
    setSelectedStartDate,
    numGuests,
    setNumGuests,
  } = useStore()


  const handleGuestsClick = (title: string, guests: number) => {
    setNumGuests(guests)
    setSelectedGuestsTitle(title)
  }
  
  const onDatesChange = (date: string) => {
    setSelectedStartDate(date)
    if (date) {
      setSelectedDateTitle(moment(date).format('DD-MM-YYYY'))
    }
  }
  

  const onClearDates = () => {
    setSelectedStartDate('')
  }

  

  const getMeetingRooms = () => {
    const date = selectedStartDate ? moment(selectedStartDate).format('DD-MM-YYYY') : undefined
    const guests = numGuests ? numGuests : undefined
    const queryparameters = encodeQueryParams({ guests: NumberParam, date: StringParam }, { guests, date })

    const url = `${link}?${stringify(queryparameters)}`
    navigate(url)
  }

  return (
    <section className='relative pb-20 text-white bg-dark-400'>
      <div className='sticky top-0 z-10 flex flex-col mt-20 border-t border-current lg:flex-row'>
        <div className='relative lg:w-6/12'>
          <Text variant='intro' weight='heavy' className='py-6 leading-none gutter'>
            {t('meetings-harpa')}
          </Text>
          <div className='absolute top-0 right-0 hidden w-px bg-current h-1/2 lg:block' />
        </div>
        <Text variant='intro' weight='heavy' className='hidden leading-none gutter lg:px-5 lg:py-6 lg:block'>
          {t('available-rooms')}
        </Text>
      </div>
      <div className='mt-16 lg:mt-50 gutter'>
        <div className='grid w-full grid-cols-1 select-none md:grid-cols-2'>
          <div className='flex flex-col w-full md:w-3/4'>
            <HyphenText variant='h2' as='h2' weight='heavy' className='my-6'>
              {title}
            </HyphenText>

            {introText}
          </div>
          <div>
            {image && <GatsbyImage alt='' className='w-full window-clip-reverse lg:-mr-16' image={image} />}
          </div>
        </div>
      </div>

      <div className='w-full pb-32 mt-16 lg:mt-32 gutter'>
        <div className='flex flex-row flex-wrap w-full pt-12 ml-0 md:pt-0 md:flex-nowrap gutter'>
          <Dropdown
            title={t(selectedGuestsTitle)}
            className='z-20 flex-grow flex-shrink !w-full lg:!w-1/3 min-w-0 md:mr-4 md:z-20 lg:flex-shrink-0 md:flex-grow-0'
            items={[
              { title: '1-5', onClick: () => handleGuestsClick('1-5', 5) },
              { title: '5-10', onClick: () => handleGuestsClick('5-10', 10) },
              { title: '10-15', onClick: () => handleGuestsClick('10-15', 15) },
              { title: '15-20', onClick: () => handleGuestsClick('15-20', 20) },
            ]}            
          />

          <DatePickerSingle
            className='z-20 flex-grow flex-shrink min-w-0 !w-1/3 mt-3 md:mr-4 md:mt-0 lg:flex-shrink-0 md:flex-grow-0'
            clearText={t('clear')}
            locale={locale}
            onClearDates={onClearDates}
            onDatesChange={onDatesChange}
            numberOfMonths={1}
            isDayBlock={getBlockedDays}
            placeholder='Dagsetning'
            startDate={selectedStartDate ? moment(selectedStartDate) : null}
            title={t(selectedDateTitle)}
          />

          <Button
            onClick={getMeetingRooms}
            variant='yellow'
            className={cn('w-full mb-12 max-h-[56px] md:w-3/4 mt-3 md:mt-0')}
            icon
          >
            {t('see-meeting-room-availability')}
          </Button>
        </div>
      </div>
    </section>
  )
}

export default BookMeetingSection
