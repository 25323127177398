import React, { useEffect, useRef, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useMenuState } from 'reakit/Menu'
import { motion } from 'framer-motion'
import moment, { Moment } from 'moment'
import 'moment/locale/is'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import Icon from '../Icon/Icon'
import Text from '../Text'
import cn from 'classnames'
import { set } from 'lodash'
import is from '../Hyphen/patterns/is'

require('./Datepicker.css')

export interface DatepickerProps {
  className?: string
  clearText?: string
  isDayBlock?: (day: moment.Moment) => boolean
  locale?: string
  numberOfMonths: number
  onClearDates: any
  onDatesChange: any
  placeholder: string
  startDate: any
  title: string
  onClickAdd?: () => void
  onPrevMonthClick?: ((newCurrentMonth: moment.Moment) => void) | undefined
  onNextMonthClick?: ((newCurrentMonth: moment.Moment) => void) | undefined
}

export const DatePickerSingle = ({
  className = '',
  clearText = 'Clear',
  locale = 'is',
  isDayBlock,
  numberOfMonths,
  onClearDates,
  onDatesChange,
  onNextMonthClick,
  onPrevMonthClick,
  onClickAdd,
  startDate,
  title,
  placeholder,
}: DatepickerProps) => {
  const [hover, setHover] = useState(false)
  const menu = useMenuState({ animated: true })
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    moment.locale(locale)
  }, [])

  const arrowVariants = {
    hover: { translateX: 1, translateY: 3 },
  }

  const buttonClickedRef = useRef(false);

  const onFocusChange = ({ focused }: { focused: boolean }) => {
    // Directly use the provided focused value
    setIsFocused(focused);
  }

  const onButtonClick = () => {
    // Directly toggle isFocused
    setIsFocused(prevIsFocused => !prevIsFocused);
    onClickAdd && onClickAdd();
  }

  return (
    <button
      className={cn(
        className,
        'flex relative text-left pl-6 pr-4 h-14 w-5/12 md:w-52 uppercase border-t border-l border-r items-center leading-none transition-colors focus:outline-none border-current text-current font-bold',
        {
          'border-b focus:bg-yellow-400 hover:bg-yellow-400 focus:bg-opacity-20 hover:bg-opacity-20': !menu.visible,
          'bg-white bg-opacity-20': menu.visible,
        },
      )}
      onClick={onButtonClick}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      <span className='flex items-center justify-between w-full h-full'>
        <Text className='uppercase' variant='sm' weight='bold'>
          {title}
        </Text>
        <motion.span
          animate={[menu.visible ? 'visible' : 'hidden', hover ? 'hover' : '']}
          variants={arrowVariants}
          className='inline-block ml-auto'
        >
          <Icon name='Arrow' className={`w-4 h-auto transform ${isFocused ? 'rotate' : 'rotate-back'}`} />
        </motion.span>
      </span>

      <SingleDatePicker
        date={startDate}
        focused={isFocused}
        onFocusChange={onFocusChange}
        onDateChange={date => {
          onDatesChange(date)
        }}
        placeholder={placeholder}
        id='pick_meeting_day'
        daySize={40}
        transitionDuration={400}
        hideKeyboardShortcutsPanel
        inputIconPosition='after'
        numberOfMonths={numberOfMonths}
        noBorder
        navPrev={<Icon className='absolute left-7 top-7' name='Chevron' />}
        navNext={<Icon className='absolute transform rotate-180 right-7 top-7' name='Chevron' />}
        renderWeekHeaderElement={day => (
          <Text className='capitalize' variant='xs'>
            {day}
          </Text>
        )}
        renderCalendarInfo={() =>
          startDate ? (
            <button onClick={onClearDates} className='px-6 pb-3 focus:outline-none'>
              <Text variant='sm' className='text-dark-400' weight='bold'>
                {clearText}
              </Text>
            </button>
          ) : (
            <div className='h-4'></div>
          )
        }
        renderMonthElement={({ month }) => (
          <Text className='capitalize' weight='light'>
            {moment(month).format('MMMM YYYY')}
          </Text>
        )}
        isDayBlocked={isDayBlock}
      />
    </button>
  )
}

export default DatePickerSingle
